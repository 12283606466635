import React, { useRef } from 'react'
import { BillingLetterPortPdfProps } from './types'
import { CfdiLetterPorte, Timbre } from '../../services/BillingService/types'
import { Row, Col } from 'reactstrap'
import imageReport from 'img/allabordo_reports.png'
import 'styles/billing_pdf.scss'
import { BillingService } from 'modules/billing/services'
import QRCode from 'react-qr-code'
import moment from 'moment'

// GENERAL COMPONENTES
const Divider = () => <hr className="hrl" />
const WrapperPdf: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => <div className="billing-pdf-body" {...props} />
const RowHeader: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="billing-rows-header" {...props} />
const BoldText: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="billing-bold-text" {...props} />
const TextSmall: React.FunctionComponent<React.HTMLProps<HTMLSpanElement>> = props => <span className="font-small font-black" {...props} />
const TitleBlack: React.FunctionComponent<React.HTMLProps<HTMLHeadingElement>> = props => <h5 className="title-black" {...props} />

const TableTh: React.FunctionComponent = ({ children }) => (
  <th className="secondary-text table-borde table-th">
    <div className="table-th-tr-content font-small">{children}</div>
  </th>
)
const TableTd: React.FunctionComponent = ({ children }) => (
  <td className="tableBorde">
    <div className="table-th-tr-content font-small">{children}</div>
  </td>
)
const HeaderParagraph: React.FunctionComponent<React.HTMLProps<HTMLParagraphElement>> = props => (
  <p className="billing-header-paragraph" {...props} />
)

// PRINCIPAL COMPONENT
export const BillingLetterPortCCP = (props: BillingLetterPortPdfProps) => {
  const printComponentRef = useRef(null)
  const { listCfdi } = props
  const getQrSrc = (timbre: Timbre) => {
    return BillingService.generateQrSat({
      timbreFiscalId: timbre.TimbreFiscalDigital.UUID,
      emisorRfc: timbre.EmisorRfc,
      receptorRfc: timbre.ReceptorRfc,
      total: timbre.Total,
      sello: timbre.Sello
    })
  }

  const getCadenaCFDI = function(timbre: Timbre) {
    const FechaTimbrado = timbre.TimbreFiscalDigital.FechaTimbrado
    const UUID = timbre.TimbreFiscalDigital.UUID
    const RfcProvCertif = timbre.TimbreFiscalDigital.RfcProvCertif
    const SelloCFD = timbre.TimbreFiscalDigital.SelloCFD
    const SelloSAT = timbre.TimbreFiscalDigital.SelloSAT
    const NoCertificadoSAT = timbre.TimbreFiscalDigital.NoCertificadoSAT
    const cadenaOrginal = `||${UUID}|${FechaTimbrado}|${RfcProvCertif}${SelloCFD}|${SelloSAT}|${NoCertificadoSAT}||`
    return cadenaOrginal
  }

  const formatDate = (date: Date) => {
    return moment(date).format('DD/MM/yyyy HH:mm:ss')
  }

  return (
    <>
      <div ref={printComponentRef}>
        {listCfdi.map((cfdi: CfdiLetterPorte) => (
          <WrapperPdf key={cfdi.id}>
            <div>
              <div className="d-flex mb-5 mt-2">
                <div className="pr-5">
                  <img className="billing-header-image" src={imageReport} />
                </div>
                <div>
                  <h5 className="billing-header-title">{cfdi.timbre.EmisorNombre}</h5>
                  <HeaderParagraph>RFC:{cfdi.timbre.EmisorRfc}</HeaderParagraph>
                  <HeaderParagraph>Tipo de comprobante: {cfdi.tipo_cfdi}</HeaderParagraph>
                  <HeaderParagraph>Versión {cfdi.timbre.Version}</HeaderParagraph>
                  <HeaderParagraph>Lugar de Expedición: {cfdi.timbre.LugarExpedicion}</HeaderParagraph>
                  <HeaderParagraph>Fecha de Expedición: {formatDate(cfdi.timbre.Fecha)}</HeaderParagraph>
                  <HeaderParagraph>Régimen Fiscal: {cfdi.timbre.EmisorRegimenFiscal}</HeaderParagraph>
                  <HeaderParagraph>Folio Fiscal: {cfdi.timbre.TimbreFiscalDigital.UUID}</HeaderParagraph>
                  <HeaderParagraph>No de Serie de Certificado del CSD: {cfdi.timbre.NoCertificado}</HeaderParagraph>
                </div>
              </div>
              <Divider />
              <RowHeader>Datos del cliente</RowHeader>
              <div className="d-flex w-100">
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Cliente:</BoldText>
                  </div>
                  <div>{cfdi.timbre.ReceptorNombre}</div>
                </div>

                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Uso de CFDI:</BoldText>
                  </div>
                  <div>{cfdi.timbre.ReceptorRegimenFiscal}</div>
                </div>
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Carta porte:</BoldText>
                  </div>
                  <div>{cfdi.parcel_tracking_code}</div>
                </div>
              </div>
              <div>
                <div className="d-flex w-50">
                  <div className="mr-5">
                    <BoldText>Rfc:</BoldText>
                  </div>
                  <div className="ml-4">{cfdi.timbre.ReceptorRfc}</div>
                </div>
              </div>
              <Divider />
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>Cantidad</TableTh>
                    <TableTh>ProdServicio</TableTh>
                    <TableTh>CveUnidad</TableTh>
                    <TableTh>Descripción</TableTh>
                    <TableTh>Valor Unitario</TableTh>
                    <TableTh>Descuento</TableTh>
                    <TableTh>Importe</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.Concepto.map(concept => (
                    <tr ng-if="cfdi.timbre.Concepto.length> 0" key={concept.ClaveProdServ}>
                      <TableTd>{concept.Cantidad}</TableTd>
                      <TableTd>{concept.ClaveProdServ}</TableTd>
                      <TableTd>{concept.ClaveUnidad}</TableTd>
                      <TableTd>{concept.Descripcion}</TableTd>
                      <TableTd>{concept.ValorUnitario}</TableTd>
                      <TableTd>{concept.Descuento === undefined ? 0 : concept.Descuento}</TableTd>
                      <TableTd>{cfdi.timbre.Concepto[0].Importe}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Divider />
              <Row>
                <Col sm={6}>
                  <RowHeader style={{ margin: 0 }}>Total Con letra:</RowHeader>
                </Col>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>Subtotal</RowHeader>
                    <TextSmall>{cfdi.timbre.SubTotal}</TextSmall>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextSmall>({cfdi.timbre.totalLetra} 00/100 MX)</TextSmall>
                </Col>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>IVA Trasladado (% 0.16)</RowHeader>
                    <TextSmall>{cfdi.timbre.TotalImpuestosTrasladados}</TextSmall>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="w-100 d-flex text-right justify-content-end">
                    <RowHeader style={{ margin: '0px 10px' }}>Total</RowHeader>
                    <TextSmall>{cfdi.timbre.Total}</TextSmall>
                  </div>
                </Col>
              </Row>

              <Divider />

              <Row style={{ marginLeft: '5px' }}>
                <h3>Complemento Carta Porte</h3>
              </Row>
              <Row style={{ marginLeft: '5px' }}>
                <p>Version:{cfdi.timbre.complementoCartaPorte.cartaPorte.Version}</p>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>TranspInternac</TableTh>
                    <TableTh>EntradaSalidaMerc</TableTh>
                    <TableTh>PaisOrigenDestino</TableTh>
                    <TableTh>ViaEntradaSalida</TableTh>
                    <TableTh>TotalDistRec</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  <tr>
                    <TableTd>{cfdi.timbre.complementoCartaPorte.cartaPorte.TranspInternac}</TableTd>
                    <TableTd>-</TableTd>
                    <TableTd>-</TableTd>
                    <TableTd>-</TableTd>
                    <TableTd>{cfdi.timbre.complementoCartaPorte.cartaPorte.TotalDistRec}</TableTd>
                  </tr>
                </tbody>
              </table>
              <Divider />
              <Row style={{ marginLeft: '5px' }}>
                <h3>Ubicaciones</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>Tipo Ubicación</TableTh>
                    <TableTh>RFC R. Dest.</TableTh>
                    <TableTh>FechaHoraSalidaLlegada</TableTh>
                    <TableTh>Calle</TableTh>
                    <TableTh>Municipio</TableTh>
                    <TableTh>Estado</TableTh>
                    <TableTh>Pais</TableTh>
                    <TableTh>C.P</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.ubicaciones.map(ubi => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.ubicaciones.length> 0" key={ubi.IDUbicacion}>
                      <TableTd>{ubi.TipoUbicacion}</TableTd>
                      <TableTd>{ubi.RFCRemitenteDestinatario}</TableTd>
                      <TableTd>{ubi.FechaHoraSalidaLlegada}</TableTd>
                      <TableTd>{ubi.domicilio.Calle}</TableTd>
                      <TableTd>{ubi.domicilio.Municipio}</TableTd>
                      <TableTd>{ubi.domicilio.Estado}</TableTd>
                      <TableTd>{ubi.domicilio.Pais}</TableTd>
                      <TableTd>{ubi.domicilio.CodigoPostal}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Divider />
              <Row style={{ marginLeft: '5px' }}>
                <h3>Mercancias</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>PesoBrutoTotal</TableTh>
                    <TableTh>UnidadPeso</TableTh>
                    <TableTh>PesoNetoTotal</TableTh>
                    <TableTh>NumTotalMercancias</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.mercancia.map(mercancia => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.mercancia.length> 0" key={mercancia.PesoEnKg}>
                      <TableTd>{mercancia.PesoEnKg}</TableTd>
                      <TableTd>{mercancia.ClaveUnidad}</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>{mercancia.Cantidad}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Divider />
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>BienesTransp</TableTh>
                    <TableTh>Descripcion</TableTh>
                    <TableTh>Cantidad</TableTh>
                    <TableTh>ClaveUnidad</TableTh>
                    <TableTh>PesoEnKg</TableTh>
                    <TableTh>MaterialPeligroso</TableTh>
                    <TableTh>CveMaterialPeligroso</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.mercancia.map(mercancia => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.mercancia.length> 0" key={mercancia.PesoEnKg}>
                      <TableTd>{mercancia.BienesTransp}</TableTd>
                      <TableTd>{mercancia.Descripcion}</TableTd>
                      <TableTd>{mercancia.Cantidad}</TableTd>
                      <TableTd>{mercancia.ClaveUnidad}</TableTd>
                      <TableTd>{mercancia.PesoEnKg}</TableTd>
                      <TableTd>No</TableTd>
                      <TableTd>N/A</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Divider />
              <Row style={{ marginLeft: '5px' }}>
                <h3>Autotransporte</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>PermSCT</TableTh>
                    <TableTh>NumPermisoSCT</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.autotransporte.map(Aut => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.autotransporte.length> 0" key={Aut.NumPermisoSCT}>
                      <TableTd>{Aut.PermSCT}</TableTd>
                      <TableTd>{Aut.NumPermisoSCT}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row style={{ marginLeft: '5px' }}>
                <h3>Identificación Vehicular</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>ConfigVehicular</TableTh>
                    <TableTh>PlacaVM</TableTh>
                    <TableTh>AñoModeloVM</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.autotransporte.map(auto => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.autotransporte.length> 0" key={auto.IdentificacionVehicular.PlacaVM}>
                      <TableTd>{auto.IdentificacionVehicular.ConfigVehicular}</TableTd>
                      <TableTd>{auto.IdentificacionVehicular.PlacaVM}</TableTd>
                      <TableTd>{auto.IdentificacionVehicular.AnioModeloVM}</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row style={{ marginLeft: '5px' }}>
                <h3>Seguros</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>AseguraRespCivil</TableTh>
                    <TableTh>PolizaRespCivil</TableTh>
                    <TableTh>AseguraMedAmbiente</TableTh>
                    <TableTh>PolizaMedAmbiente</TableTh>
                    <TableTh>AseguraCarga</TableTh>
                    <TableTh>PolizaCarga</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.autotransporte.map(seguro => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.autotransporte.length> 0" key={seguro.Seguros.PolizaRespCivil}>
                      <TableTd>{seguro.Seguros.AseguraRespCivil}</TableTd>
                      <TableTd>{seguro.Seguros.PolizaRespCivil}</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>N/A</TableTd>
                      <TableTd>N/A</TableTd>
                      <TableTd>N/A</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row style={{ marginLeft: '5px' }}>
                <h3>Tipos Figura</h3>
              </Row>
              <table className="w-100">
                <thead>
                  <tr>
                    <TableTh>TipoFigura</TableTh>
                    <TableTh>RFCFigura</TableTh>
                    <TableTh>NumLicencia</TableTh>
                    <TableTh>NombreFigura</TableTh>
                    <TableTh>NumRegIdTribFigura</TableTh>
                    <TableTh>ResidenciaFiscalFigura</TableTh>
                    <TableTh>Estado</TableTh>
                    <TableTh>Pais</TableTh>
                    <TableTh>Codigo postal</TableTh>
                  </tr>
                </thead>
                <tbody className="trTable">
                  {cfdi.timbre.complementoCartaPorte.figuraTransporte.map(figura => (
                    <tr ng-if="cfdi.timbre.complementoCartaPorte.figuraTransporte.length> 0" key={figura.RFCFigura}>
                      <TableTd>{figura.TipoFigura}</TableTd>
                      <TableTd>{figura.RFCFigura}</TableTd>
                      <TableTd>{figura.NumLicencia}</TableTd>
                      <TableTd>{figura.NombreFigura}</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>-</TableTd>
                      <TableTd>-</TableTd>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Divider />
            </div>
            <div style={{ marginTop: 100 }}>
              <b>SELLO DIGITAL DEL CFDI</b>
              <p className="AjusteSello" style={{ fontSize: 10, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloCFD}
              </p>
              <b>SELLO DEL SAT</b>
              <p className="AjusteSello" style={{ fontSize: 10, color: '#000' }}>
                {cfdi.timbre.TimbreFiscalDigital.SelloSAT}
              </p>
              <br />
              <div className="d-flex">
                <div className="w-20">
                  <QRCode value={getQrSrc(cfdi.timbre)} size={170} />
                </div>
                <div style={{ maxWidth: 800, paddingLeft: 20 }}>
                  <TitleBlack>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACIÓN DIGITAL</TitleBlack>
                  <br />
                  <p className="AjusteSello" style={{ fontSize: 9, color: '#000' }}>
                    {getCadenaCFDI(cfdi.timbre)}
                  </p>
                  <div>
                    <div className="d-flex">
                      <RowHeader>No de Serie del Certificado del SAT:</RowHeader>
                      <div className="ml-2">{cfdi.timbre.NoCertificado}</div>
                    </div>
                    <div className="d-flex">
                      <RowHeader>Fecha y hora de certificación:</RowHeader>
                      <div className="ml-2">{cfdi.timbre.TimbreFiscalDigital.FechaTimbrado}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WrapperPdf>
        ))}
      </div>
    </>
  )
}
