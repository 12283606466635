import { BillingService } from 'modules/billing/services'
import React, { useEffect, useState } from 'react'
import { BillingLayoutState } from './types'
import Container from 'reactstrap/lib/Container'
import { ABanner } from 'shared/components/common'
import { BillingLetterPortCCP } from 'modules/billing/components/BillingLetterPortPDF/BillingLetterPortCCP'

const initialState: BillingLayoutState = {
  isLoading: false,
  hasBillingData: false,
  err: '',
  code: '',
  listCfdi: null,
  billingType: 'letterPorte'
}

export const GetCFDILayout: React.FunctionComponent<{ code: string }> = ({ code }) => {
  const [state, setState] = useState(initialState)

  const handleSearch = async () => {
    setState({ ...state, isLoading: true, hasBillingData: false })
    switch (state.billingType) {
      case 'letterPorte':
        try {
          if (code.startsWith('G')) {
            const cfdiData = await BillingService.getComplementLetterPortDataCCP(code)
            setState({ ...state, isLoading: false, hasBillingData: true, listCfdi: cfdiData })
          } else {
            const cfdiData = await BillingService.getCfdiManifestCcp(code)
            setState({ ...state, isLoading: false, hasBillingData: true, listCfdi: cfdiData })
          }
        } catch (error) {
          setState({ ...state, isLoading: true, hasBillingData: false })
          console.log('Error al obtener los datos de la carta porte: ', error)
        }
        break
      default:
        console.log('')
        break
    }
  }

  useEffect(() => {
    if (code) {
      handleSearch()
    }
  }, [])

  return (
    <>
      <ABanner title="CFDI Complemento Carta porte" titleClassName="big-white" titleSize={12} />
      <Container className="mt-5">
        {!state.isLoading && !state.hasBillingData}
        {state.isLoading && <p>Cargando...</p>}
        {state.hasBillingData && <BillingLetterPortCCP listCfdi={state.listCfdi || []} />}
      </Container>
    </>
  )
}
