import React from 'react'
import { queryParams } from 'core/utils'
import { navigate } from 'gatsby'
import { GetCFDILayout } from 'modules/billing/pages/getCFDI/GetCFDILayout'

interface ConsultaCfdiParms {
  code: string
}

export default class CfdiCcpDigital extends React.Component<PageProps> {
  state = { code: '' }
  componentDidMount() {
    const params = queryParams<ConsultaCfdiParms>(this.props.location)
    if (!params.code) {
      return navigate('/')
    }
    this.setState({ code: params.code })
  }

  render() {
    return <>{this.state.code && <GetCFDILayout code={this.state.code} />}</>
  }
}
